import moment from 'moment';
import { OverlappingFieldViewModel } from './archiving-internal.provider';
import {
  FieldModel,
  RegionModel,
  RegionNameMapViewModel,
  UnarchiveErrorModel
} from './archiving.model';

interface Version {
  valid_since: string;
  valid_until: string | null;
}
/**
 * Check if there is a temporal gap between the version at `currentIndex` and the previous version.
 * @param history Array of field versions, sorted in descending order (current version = index 0)
 * @param currentIndex Index to check for temporal gaps
 * @returns true if there is a temporal gap, false otherwise
 */
export function checkTemporalGap(history: Version[], currentIndex: number) {
  const currentVersion = history?.[currentIndex];
  const prevVersion = history?.[currentIndex + 1];

  if (
    typeof currentVersion?.valid_since === 'string' &&
    typeof prevVersion?.valid_until === 'string'
  ) {
    const validUntil = new Date(prevVersion.valid_until);

    // To detect a archive, we check if the valid since of the current version is after the valid until (+ 1 day) of previous version
    validUntil.setDate(validUntil.getDate() + 1);
    return new Date(currentVersion.valid_since) > validUntil;
  }
  return false;
}

export function getFieldWithParentName(field: FieldModel, regionNames: RegionNameMapViewModel) {
  const parentName = regionNames[field.parent_region_id];
  const name = (parentName ? parentName + ' / ' : '') + field.name;

  return {
    ...field,
    name
  };
}

export function getRegionChildrenName(children: RegionModel[], parentName: string = '') {
  const regionNames: RegionNameMapViewModel = {};
  for (let region of children) {
    const name = (parentName ? parentName + ' / ' : '') + region.name;
    regionNames[region.id] = name;
    Object.assign(regionNames, getRegionChildrenName(region.children_region, name));
  }
  return regionNames;
}

export const mapFieldToViewModel = (
  field:
    | UnarchiveErrorModel['errors'][0]['field']
    | UnarchiveErrorModel['errors'][0]['fields_overlapped'][0]
): Omit<OverlappingFieldViewModel, 'isCurrentField'> => ({
  id: field.field_id,
  name: field.field_name,
  area: parseFloat(field.calculated_area as unknown as string),
  geometry: field.geometry,
  overlapPeriod: field.overlap_period
});

export const mapOverlappingFields = (
  currentField: UnarchiveErrorModel['errors'][0]['field'],
  overlappingFields: UnarchiveErrorModel['errors'][0]['fields_overlapped']
): OverlappingFieldViewModel[] => {
  const mappedCurrentField: OverlappingFieldViewModel = {
    ...mapFieldToViewModel(currentField),
    isCurrentField: true
  };

  const mappedOverlappingFields: OverlappingFieldViewModel[] = overlappingFields.map((field) => ({
    ...mapFieldToViewModel(field),
    isCurrentField: false
  }));

  return [mappedCurrentField, ...mappedOverlappingFields];
};

const MIN_DATE_YEAR = 1970;
const MAX_DATE_YEAR = 2038;

export function getFormattedDateToOverlapFields(
  date: string | Date,
  dateFormat: string,
  t: (key: string) => string
): string {
  if (!date) {
    return '';
  }

  const momentDate = moment(date);

  if (momentDate.year() === MIN_DATE_YEAR) {
    return t('archiving.notifications.unarchive_error_table.creation');
  }

  if (momentDate.year() === MAX_DATE_YEAR) {
    return t('archiving.notifications.unarchive_error_table.latest_version');
  }

  return momentDate.format(dateFormat);
}
